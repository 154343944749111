'use client';

import { useRouter } from 'next/navigation';

import Button from '@components/button';

import { UFOIcon } from '@shared/assets';

const NotFound = () => {
  const router = useRouter();

  return (
    <div className="flex min-h-screen w-full items-center justify-center">
      <div className="pointer-events-none absolute left-[-90px] top-[-90px] hidden h-[660px] w-[660px] animate-headerGradient rounded-full bg-brandPrimary opacity-20 blur-[250px] lg:flex" />
      <div className="flex w-full max-w-[360px] flex-col items-center justify-center px-4 text-center">
        <div className="roundedBlTr flexCenter mb-10 size-24 border border-neutral700 bg-neutral900">
          <UFOIcon className="size-15 text-neutral300" />
        </div>
        <h1 className="heading-xxlarge mb-4 text-neutral400 sm:!text-[56px]">
          ERROR <span className="text-white">404</span>
        </h1>
        <p className="text-light80 font-large font-normal">
          The page you are looking for doesn&apos;t exist or has been moved.
        </p>
        <Button className="mt-10" onClick={() => router.push('/')}>
          Go home
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
